import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n/index'
import router from './router'
// import './assets/styles/tailwind.css';;
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole();
// Vue.use(vConsole)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
