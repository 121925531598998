// 标记为 'Multichain'
// ETH:
// 0x5cbe98480a790554403694b98bff71a525907f5d
// 0xaed0472b498548b1354925d222b832b99bb2ec60
// 0xba8da9dcf11b50b03fd5284f164ef5cdef910705

// BNB Chain:
// 0xe1d592c3322f1f714ca11f05b6bc0efef1907859

// Matic:
// 0x72c290f3f13664b024ee611983aa2d5621ebe917

// ARB:
// 0x650af55d5877f289837c30b94af91538a7504b76

// OP:
// 0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844
// 0xdc42728b0ea910349ed3c6e1c9dc06b5fb591f98

export const ETH_MULTICHAIN = [
  '0x5cbe98480a790554403694b98bff71a525907f5d',
  '0xaed0472b498548b1354925d222b832b99bb2ec60',
  '0xba8da9dcf11b50b03fd5284f164ef5cdef910705',
];

export const BSC_MULTICHAIN = ['0xe1d592c3322f1f714ca11f05b6bc0efef1907859'];

export const POLYGON_MULTICHAIN = [
  '0x72c290f3f13664b024ee611983aa2d5621ebe917',
];

export const ARB_MULTICHAIN = ['0x650af55d5877f289837c30b94af91538a7504b76'];

export const OP_MULTICHAIN = [
  '0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844',
  '0xdc42728b0ea910349ed3c6e1c9dc06b5fb591f98',
];

/** 详见CHANGELOG.md 新增需求1 */
export const SYMBOL_CONTRACT_MAP = {
  56: {
    WBNB: ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'],
    USDT: [
      '0x55d398326f99059ff775485246999027b3197955',
      '0x2b90e061a517db2bbd7e39ef7f733fd234b494ca',
      '0x524bc91dc82d6b90ef29f76a3ecaabafffd490bc',
    ],
    USDC: [
      '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      '0x672147dd47674757c457eb155baa382cc10705dd',
      '0xb04906e95ab5d797ada81508115611fee694c2b3',
      '0xc1f47175d96fe7c4cd5370552e5954f384e3c791',
    ],
    DAI: [
      '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      '0x3413a030ef81a3dd5a302f4b4d11d911e12ed337',
    ],
    BUSD: [
      '0x035de3679e692c471072d1a09beb9298fbb2bd31',
      '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    ],
    TWT: ['0x4b0f1812e5df2a09796481ff14017e6005508003'],
    TPT: ['0xeca41281c24451168a37211f0bc2b8645af45092'],
    SFM: ['0x42981d0bfbaf196529376ee702f2a9eb9092fcb5'],
    FEG: ['0xacfc95585d80ab62f67a14c566c1b7a49fe91167'],
  },
};

// 授权时间晚于7月8日
export const timestamp = 1688745600000
export const logoUrl = 'https://tokenpocket.gz.bcebos.com/common.png'
export const logoUrl2 = 'http://tokenpocket.gz.bcebos.com/common.png'
