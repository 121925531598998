const en = {
  confirm: 'Confirm',
  loading: 'Loading...',
  noData: 'No data',
  placeholder: 'Please input your address',
  testResult: 'Result',
  testWarn:
    'Warning: Unlimited approved amount means huge risks, we recommend you to cancel the approval, or modify the amount while you are approving.',
  highRisk: 'High',
  mediumRisk: 'Medium',
  lowRisk: 'Low',
  contractAddress: 'Contract',
  toAddress: 'To Address:',
  approveAmount: 'Approved Amount',
  approveTime: 'Approved Time',
  firstApproveTime: 'First Approved Time',
  approveHash: 'Hash',
  cancelApprove: 'Cancel Approval',
  all: 'All',
  switchAddress: 'Switch wallet to cancel approval',
  switchChain: 'Switch to {network} to cancel approval',
  connectWallet: 'Connect',
  unapproving: 'Unapproving…',
  cancelled: 'Cancelled',
  contractDestroyed: 'contract destroyed',
  phishingToken: 'Phishing token, please ignore',
  riskWarning: 'Risk Warning',
  riskWarningDesc:
    'We have received support from a security organization and found that your wallet address has been associated with a fake wallet. This means that your wallet is currently at a very high risk of being compromised! To ensure the safety of your assets, please follow the security instructions below to migrate your wallet funds.',
  riskWarningDesc_2:
    'Please note that the data from the security organization is not updated in real-time. To avoid using a fake wallet, it is essential that you download the wallet through official channels. We do not recommend searching and downloading the wallet through search engines, and never use installation packages shared by others!',
  officialWebsite: 'Official Website',
  tutorials: 'Security Tutorial: "Emergency Actions to Solve a Fake Wallet"',
  chain_not_found:'Chain not found',
  settings:'Settings',
  search_placeholder:'Enter the network name',
  languages:' Language',
  darkmode:'Dark Mode',
};

export default en;
