<template>
  <div class="fake-wallet">
    <div class="warning-title">
      <img src="../assets/warning.png" alt="" />
      <span>{{ $t('riskWarning') }}</span>
    </div>
    <div class="warning-desc">
      <p>
        {{ $t('riskWarningDesc') }}
      </p>
      <p>
        {{ $t('riskWarningDesc_2') }}
      </p>
    </div>
    <div class="official-website" :class="{ english: $i18n.locale === 'en' }">
      <a
        class="website-item"
        :class="{ english: $i18n.locale === 'en' }"
        :href="item.url"
        target="_blank"
        v-for="(item, index) in websiteList"
        :key="index"
      >
        <div class="website">
          <img src="../assets/secure.png" alt="" />
          <span>{{ item.label + ' ' + (index + 1) + ' : ' + item.url }}</span>
        </div>
        <img class="arrow" src="../assets/arrow-2.png" alt="" />
      </a>
    </div>
    <a
      class="tutorials"
      :class="{ english: $i18n.locale === 'en' }"
      :href="tutorialUrl"
      target="_blank"
    >
      <img class="tutorials-img" src="../assets/tutorials.png" alt="" />
      <span>{{ $t('tutorials') }}</span>
      <img class="arrow-img" src="../assets/arrow-2.png" alt="" />
    </a>
  </div>
</template>

<script>
import { TP_SERVER_DOMAIN } from '../config';
export default {
  computed: {
    websiteList() {
      return [
        { label: this.$t('officialWebsite'), url: 'https://tokenpocket.pro' },
        { label: this.$t('officialWebsite'), url: 'https://tpwallet.io' },
      ];
    },
    tutorialUrl() {
      if (this.$i18n.locale === 'zh') {
        return 'https://help.tpwallet.io/cn/security-knowledge/protective-measures/guide';
      } else {
        return 'https://help.tpwallet.io/en/security-knowledge/security-measure/guidelines';
      }
    },
  },
  methods: {
    async getFakeWalletData(address) {
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/wallet/leaked?address=${address}`
        );
        const res = await response.json();
        if (res.result === 0) {
          console.log('getFakeWalletData', res);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fake-wallet {
  background: #d51e14;
  padding: 32px 20px;
  .warning-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 36px;
      height: 36px;
    }
    span {
      margin-top: 12px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
  }
  .warning-desc {
    margin-top: 12px;
    max-width: 1008px;
    margin: 0 auto;
    margin-top: 12px;
    p {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }
    :nth-child(2) {
      margin-top: 14px;
    }
  }
  .official-website {
    width: 518px;
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.english {
      width: 618px;
    }
    .website-item {
      width: 252px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 12px;
      padding: 12px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.english {
        width: 302px;
      }
      .website {
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
        }
        span {
          margin-left: 5px;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: left;
          color: #101010;
          // line-height: 17px;
        }
      }
      .arrow {
        width: 10px;
        height: 10px;
      }
    }
  }
  .tutorials {
    width: 518px;
    margin: 0 auto;
    margin-top: 12px;
    background: #ffffff;
    border-radius: 12px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.english {
      width: 618px;
    }
    .tutorials-img {
      width: 16px;
      height: 16px;
    }
    span {
      margin-left: 10px;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #101010;
    }
    .arrow-img {
      margin-left: 10px;
      width: 10px;
      height: 10px;
    }
  }
}
@media screen and (max-width: 767px) {
  .fake-wallet {
    padding: 24px 20px;
    margin: 0;
    .warning-title {
      flex-direction: row;
      img {
        width: 28px;
        height: 28px;
      }
      span {
        margin-top: 0;
        margin-left: 6px;
        font-size: 16px;
      }
    }
    .warning-desc {
      max-width: none;
      padding: 0;
      p {
        text-align: left;
        color: #ffffff;
        line-height: 20px;
      }
      :nth-child(2) {
        margin-top: 14px;
      }
    }
    .official-website {
      width: 100%;
      margin-top: 16px;
      flex-direction: column;
      &.english {
        width: 100%;
      }
      .website-item {
        width: 100%;
        padding: 10px 6px 10px 12px;
        &.english {
          width: 100%;
        }
        &:nth-child(n + 2) {
          margin-top: 10px;
        }
        .website {
          span {
            font-size: 14px;
          }
        }
      }
    }
    .tutorials {
      margin-top: 10px;
      width: 100%;
      padding: 12px 6px 12px 12px;
      &.english {
        width: 100%;
      }
      span {
        flex: 1;
      }
    }
  }
}
</style>
