<template>
  <div>
    <div class="language-wrapper">
      <img class="language-img" :src="isDark ? darkImage : lightImage" />
      <div
        @mouseenter="togglePanel(true)"
        @mouseleave="togglePanel(false)"
        class="selectedLanguage"
        :class="{ hint: showPanel }"
        :style="{ color: isDark ? '#FFF' : '' }"
      >
        {{ currentLanguageTitle }}
        <div v-if="showPanel" class="language-panel">
          <div
            class="languagein"
            v-for="language in languages"
            :key="language.lang"
            @click="changeLanguage(language)"
          >
            {{ language.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isDark"],
  data() {
    return {
      showPanel: false,
      currentLanguage: this.$i18n.locale,
      // buttonText: this.$i18n.locale,
      languages: [
        { title: "简体中文", lang: "zh" },
        { title: "English", lang: "en" },
      ],
      darkImage: require("../assets/language_light.png"),
      lightImage: require("../assets/language.png"),
    };
  },
  computed: {
    currentLanguageTitle() {
      const language = this.languages.find(
        (lang) => lang.lang === this.currentLanguage
      );
      return language ? language.title : "";
    },
  },
  methods: {
    togglePanel(bool) {
      this.showPanel = bool;
    },
    changeLanguage(language) {
      // this.$emit('changeLanguage',lang)
      this.currentLanguage = language.lang;
      // this.buttonText = language.title;
      this.showPanel = false;
      this.$i18n.locale = language.lang;
    },
  },
};
</script>
<style lang="less">
@import "./languages.less";
</style>
