<template>
  <div>
    <span 
      class="weui-switch" 
      :class="{'weui-switch-on': checked, 'big': size === 'big'}" 
      :value="value" 
      @click.stop="toggle"
    ></span>
  </div>
</template>

<script>
  export default {
    name: 'Switcher',
    props: {
      value: {
        type: Boolean
      },
      size: {
        type: String
      }
    },
    data() {
      return {
        checked: this.value
      }
    },
    watch: {
      value(val) {
        this.checked = val
      }
    },
    methods: {
      toggle() {
        this.checked = !this.checked;
        this.$emit('switch', !this.checked);
      }
    }
  }
</script>

<style lang="less">
  @import '../_variables.less';
  .weui-switch {
    display: block;
    position: relative;
    width: 28px;
    height: 16px;
    border: 1px solid var(--switch-border);
    outline: 0;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: var(--switch-bg);
    transition: background-color 0.1s, border 0.1s;
    cursor: pointer;
  }
  .weui-switch:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 14px;
    border-radius: 15px;
    background-color: var(--switch-bg);
    transition: transform 0.35s cubic-bezier(0.45, 1, 0.4, 1);
  }
  .weui-switch:after {
    content: " ";
    position: absolute;
    top: 2px;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: var(--switch-before-bg);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    transition: transform 0.35s cubic-bezier(0.4, 0.4, 0.25, 1.35);
  }
  .weui-switch-on {
    border-color: var(--switch-on);
    background-color: var(--switch-on);
  }
  .weui-switch-on:before {
    border-color: var(--switch-on);
    background-color: var(--switch-on);
  }
  .weui-switch-on:after {
    transform: translateX(12px);
  }

  .big {
    &.weui-switch {
      width: 65px;
      height: 36px;
      border-radius: 18px;
    }
    &.weui-switch:before {
      width: 63px;
      height: 34px;
      border-radius: 32.5px;
    }
    &.weui-switch:after {
      width: 30px;
      height: 30px;
      border-radius: 17px;
      background-image: url('../assets/sun.png');
      background-size: 18px 18px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    &.weui-switch-on:after {
      transform: translateX(29px);
      background-image: url('../assets/moon.png');
    }
  }

  @media screen and (max-width: 700px) {
    .big {
      &.weui-switch {
        width: 40px;
        height: 24px;
        border-radius: 12px;
      }
      &.weui-switch:before {
        width: 38px;
        height: 22px;
        border-radius: 11px;
      }
      &.weui-switch:after {
        width: 18px;
        height: 18px;
        border-radius: 11px;
        background-size: 12px 12px;
      }
      &.weui-switch-on:after {
        transform: translateX(16px);
      }
    }
  }
</style>
