<template>
  <div class="languagem">
    <div
      class="language-wrapper_m"
      :class="{ darkmode: mode, lightmode: !mode }"
    >
      <div
        class="selectedLanguage_m"
        @click="togglePanel(!showPanel)"
        :class="{ hint: showPanel }"
      >
        {{ currentLanguageTitle }}
        <div class="language-panel_m" v-if="showPanel">
          <div class="language-back_m">
            <img :src="arrowImg" />
          </div>
          <div
            class="languagein_m"
            v-for="language in languages"
            :key="language.lang"
            @click.stop="changeLanguage(language)"
          >
            {{ language.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPanel: false,
      currentLanguage: this.$i18n.locale,
      // buttonText: this.$i18n.locale,
      languages: [
        { title: "简体中文", lang: "zh" },
        { title: "English", lang: "en" },
      ],
      languageImg: require(`../assets/language.png`),
      arrowImg: require(`../assets/arrow-w.png`),
    };
  },
  computed: {
    currentLanguageTitle() {
      const language = this.languages.find(
        (lang) => lang.lang === this.currentLanguage
      );
      return language ? language.title : "";
    },
  },
  methods: {
    togglePanel(bool) {
      this.showPanel = bool;
    },
    changeLanguage(language) {
      // this.$emit('changeLanguage',lang)
      this.currentLanguage = language.lang;
      // this.buttonText = language.title;
      this.showPanel = false;
      this.$i18n.locale = language.lang;
    },
  },
};
</script>

<style lang="less">
@import "./languagesmobile.module.less";
</style>
