<template>
  <div class="hello" :class="{ inline: status !== 'init' }">
    <div
      class="menuemask"
      @click="changModalVisiable(false)"
      :class="{
        modaldisappear: !modalShow,
      }"
    >
      <div
        class="menue-panel"
        :class="{ darkmode: isDarkMode }"
        @click="stoppop"
      >
        <div class="mobile-menue">
          <div class="mobile-menue-title">
            {{ $t("settings") }}
            <img :src="closePng" @click="changModalVisiable(false)" />
          </div>
          <div class="mobile-menue-detail" :style="{ height: '50px' }">
            <span>{{ $t("languages") }}</span>
            <div class="detail-right">
              <MobileLanguages :mode="isDarkMode" />
              <img :src="rightArrow" />
            </div>
          </div>
          <div class="mobile-menue-detail" :style="{ height: '50px' }">
            {{ $t("darkmode") }}
            <Switcher
              class="switcher"
              size="big"
              :value="isDarkMode"
              @switch="isDarkMode = !isDarkMode"
            ></Switcher>
          </div>
          <div
            class="mobile-menue-detail"
            v-if="!selectedAddress"
            :style="{ height: '50px' }"
            @click="login('click')"
          >
            {{ $t("connectWallet") }}
            <img :src="rightArrow" />
          </div>
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              height: '50px',
            }"
            @click="login('click')"
            v-else
          >
            <img
              width="24px"
              height="24px"
              :src="chainMap[selectedChainId].logo"
              v-if="chainMap[selectedChainId]"
              class="logo"
            />
            <span :style="{ marginLeft: '8px' }" class="address">{{
              selectedAddress | omitAddress
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <header
      :style="{ background: isDarkMode ? '#0D0E0F !important' : '' }"
      :class="{ inline: status !== 'init' }"
    >
      <div class="header-content">
        <!-- <div
          :style="{
            flexDirection: 'column',
            width: '100%',
          }"
          class="inputcontent"
        >
          
        </div> -->
        <img
          :src="
            isDarkMode
              ? require('../assets/TokenPocket-B_dark.png')
              : require('../assets/TokenPocket-B.png')
          "
          @click="goHome"
          class="tp-banner"
        />
        <div
          class="search-wrap search-wrap-header"
          v-if="status !== 'init'"
          :class="{ inline: status !== 'init' }"
        >
          <div
            class="search"
            :class="{ active: !confirmVisible || chainListVisible }"
          >
            <div class="chain-wrap">
              <div
                class="chain"
                @click="chainListVisible = !chainListVisible"
                :class="{ active: chainListVisible }"
              >
                <img class="logo" :src="currentChain.logo" />
                <span class="name" v-show="status === 'init'">{{
                  currentChain.name
                }}</span>
                <img class="slide" src="../assets/an@2x.png" />
              </div>
              <div class="chain-list hidden-scrollbar" v-if="chainListVisible">
                <!-- <div v-if="status !== 'init'" :style="{ width: '100%' }">
                    <input
                      v-model="searchQuery"
                      placeholder="222222222"
                      @focus="clearInput"
                      @click.stop="() => {}"
                      class="border-none-transparent input-indent"
                      :style="{ color: isDarkMode ? '#fff' : '#000' }"
                    />
                  </div> -->
                <div
                  v-if="status !== 'init' && chainMapList.length > 12"
                  class="inputSubWrapple"
                  :class="{ isDark: isDarkMode }"
                >
                  <div class="searchIcon" :class="{ isDark: isDarkMode }"></div>
                  <input
                    v-model="searchQuery"
                    :placeholder="$t('search_placeholder')"
                    @focus="clearInput"
                    @click.stop="() => {}"
                    class="border-none-transparent input-indent"
                    :style="{
                      color: isDarkMode ? '#fff' : '#000',
                      textIndent: '32px',
                      width: '80%',
                      height: '100%',
                    }"
                  />
                </div>
                <div
                  class="chain-item"
                  :class="{ selected: chainId === currentChain.chainId }"
                  v-for="(chainId, index) in filteredChains"
                  :key="`chain${index}`"
                  @click="switchChain(chainId)"
                >
                  <img
                    :src="chainMap[chainId].logo || defaultLogo"
                    class="logo"
                  />
                  <span>{{ chainMap[chainId].name }}</span>
                  <!-- <img src="../assets/enter.png" class="enter" /> -->
                  <img
                    v-if="chainId === currentChain.chainId"
                    src="../assets/select.png"
                    class="enter"
                  />
                </div>
              </div>
            </div>
            <div class="split" v-if="status !== 'init'"></div>
            <div class="address-wrap">
              <input
                class="address"
                v-model="address"
                :placeholder="$t('placeholder')"
                @focus="confirmVisible = false"
                @blur="confirmVisible = true"
              />
              <img
                v-show="address"
                @click="address = ''"
                class="clear"
                :src="
                  isDarkMode
                    ? require('../assets/delete_dark.png')
                    : require('../assets/delete.png')
                "
              />
            </div>
          </div>
          <div
            class="confirm"
            :class="{ hidden: !confirmVisible }"
            @click="confirm"
          >
            {{ $t("confirm") }}
          </div>
        </div>
        <div
          class="showmore hidemodal"
          @click="changModalVisiable(true)"
          :class="{ showmoredark: isDarkMode }"
        >
          <!-- <img src="../assets/more.png" /> -->
        </div>
        <div
          class="header-right options"
          :style="{ minWidth: '310px', alignSelf: 'flex-start' }"
        >
          <div class="connect" v-if="!selectedAddress" @click="login('click')">
            {{ $t("connectWallet") }}
          </div>
          <div class="select-address-wrap" @click="login('click')" v-else>
            <img
              :src="chainMap[selectedChainId].logo"
              v-if="chainMap[selectedChainId]"
              class="logo"
            />
            <span class="address">{{ selectedAddress | omitAddress }}</span>
          </div>
          <Switcher
            class="switcher"
            size="big"
            :value="isDarkMode"
            @switch="isDarkMode = !isDarkMode"
          ></Switcher>
          <LauguagesVue :is-dark="isDarkMode" />
        </div>
      </div>
    </header>
    <img
      :src="require(`../assets/approve${isDarkMode ? 'dark' : 'light'}@2x.png`)"
      class="header-img"
      v-if="status === 'init'"
    />
    <main>
      <!-- :class="{ inline: status !== 'init' }" search-wrap-main -->

      <div
        class="search-wrap"
        :class="{ 'search-wrap-main': status !== 'init' }"
      >
        <div
          class="search"
          :class="{ active: !confirmVisible || chainListVisible }"
        >
          <div class="chain-wrap">
            <div
              class="chain"
              @click="resetQuery"
              :class="{ active: chainListVisible }"
            >
              <img class="logo" :src="currentChain.logo" />
              <input
                v-if="status === 'init'"
                v-model="searchQuery"
                :placeholder="$t('search_placeholder')"
                @focus="clearInput"
                @click.stop="() => {}"
                class="border-none-transparent input-indent"
                :style="{ color: isDarkMode ? '#fff' : '#000' }"
              />
              <img class="slide" src="../assets/an@2x.png" />
            </div>
            <div class="chain-list hidden-scrollbar" v-if="chainListVisible">
              <div
                v-if="status !== 'init'"
                class="inputSubWrapple"
                :class="{ isDark: isDarkMode }"
              >
                <div class="searchIcon" :class="{ isDark: isDarkMode }"></div>
                <input
                  v-model="searchQuery"
                  :placeholder="$t('search_placeholder')"
                  @focus="clearInput"
                  @click.stop="() => {}"
                  class="border-none-transparent input-indent"
                  :style="{
                    color: isDarkMode ? '#fff' : '#000',
                    textIndent: '32px',
                  }"
                />
              </div>
              <div
                v-if="!filteredChains.length"
                class="empty-view"
                :class="{ dark: isDarkMode }"
              >
                <div class="empty-in" />
                <div class="empty-text">{{ $t("chain_not_found") }}</div>
              </div>
              <div v-if="filteredChains.length">
                <div
                  class="chain-item"
                  :class="{ selected: chainId === currentChain.chainId }"
                  v-for="(chainId, index) in filteredChains"
                  :key="`chain${index}`"
                  @click="switchChain(chainId)"
                >
                  <img
                    :src="chainMap[chainId].logo || defaultLogo"
                    class="logo"
                  />
                  <span>{{ chainMap[chainId].name }}</span>
                  <!-- <img src="../assets/enter.png" class="enter" /> -->
                  <img
                    v-if="chainId === currentChain.chainId"
                    src="../assets/select.png"
                    class="enter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="split" v-if="status !== 'init'"></div>
          <div class="address-wrap" :class="{ active: !confirmVisible }">
            <input
              class="address"
              v-model="address"
              :placeholder="$t('placeholder')"
              @focus="confirmVisible = false"
              @blur="confirmVisible = true"
            />
            <img
              v-show="address"
              @click="address = ''"
              class="clear"
              :src="
                isDarkMode
                  ? require('../assets/delete_dark.png')
                  : require('../assets/delete.png')
              "
            />
          </div>
        </div>
        <div
          class="confirm"
          :class="{
            disabled: !address,
            hidden: !confirmVisible,
            isFocus: status === 'init',
          }"
          @click="confirm"
        >
          {{ $t("confirm") }}
        </div>
      </div>

      <FakeWallet v-if="isFakeWallet" />
      <div class="warn-wrap" v-if="status === 'data'">
        <img
          :src="
            isDarkMode
              ? require('../assets/risk_dark@2x.png')
              : require('../assets/risk@2x.png')
          "
        />
        <span>{{ $t("testWarn") }}</span>
      </div>
      <div class="loading-wrap" v-if="status === 'loading'">
        <img
          :src="
            isDarkMode
              ? require('../assets/loading_dark@2x.png')
              : require('../assets/loading@2x.png')
          "
          class="loading"
        />
        <p class="loading-text">{{ $t("loading") }}</p>
      </div>
      <div class="no-data-wrap" v-if="status === 'noData'">
        <img
          :src="
            isDarkMode
              ? require('../assets/empty_dark@2x.png')
              : require('../assets/empty@2x.png')
          "
          class="no-data"
        />
        <p class="no-data-text">{{ $t("noData") }}</p>
      </div>
      <div class="data-wrap" v-if="status === 'data'">
        <div class="test-type-wrap">
          <p class="test-result">{{ $t("testResult") }}</p>
          <div class="type-wrap" v-if="currentChain.chainId !== '728126428'">
            <div
              class="type"
              :class="{ select: type === item }"
              v-for="(item, index) in typeList"
              :key="`type=${index}`"
              @click="typeChange(item)"
            >
              {{ item === "all" ? $t("all") : item }}
            </div>
          </div>
          <div class="type-wrap" v-else>
            <div class="type select">{{ $t("all") }}</div>
          </div>
        </div>
        <!-- xx record(s) about 0 approval, view all > -->
        <div class="amount0-wrap" v-if="tronCancelList.length > 0">
          <div class="approve-amount" v-if="$i18n.locale === 'zh'">
            您当前有{{ tronCancelList.length }}个0授权记录
          </div>
          <div class="approve-amount" v-else>
            {{ tronCancelList.length }}
            {{ tronCancelList.length === 1 ? "record" : "records" }} about 0
            approval
          </div>
          <div class="amount-btn" @click="onShow">
            <span class="approve-amount" v-if="$i18n.locale === 'zh'"
              >点击展开查看</span
            >
            <span class="approve-amount" v-else>view all</span>
            <img src="../assets/arrow.png" alt="" />
          </div>
        </div>
        <div
          class="data"
          v-for="(item, index) in tokenList"
          :key="`data-${index}`"
          :class="{
            active:
              index === curSelectIndex &&
              !item.unapproving &&
              item.approved_amount !== '0',
          }"
          @mouseenter="onMouseEnter(index)"
        >
          <div class="data-top">
            <a
              target="_blank"
              :href="item.tokenAddressUrl || '#'"
              class="explorer-wrap"
            >
              <img
                :src="item.logo || defaultLogo"
                class="logo"
                onerror="javascript:this.src='https://tokenpocket.gz.bcebos.com/common.png';"
              />
              <span
                class="symbol"
                :class="{ 'with-type': item.type !== 'ERC-20' }"
                :title="item.token_symbol"
                >{{ item.token_symbol }}</span
              >
              <img
                :src="
                  isDarkMode
                    ? require('../assets/jump_dark.png')
                    : require('../assets/jump.png')
                "
                class="explorer"
              />
              <div class="type" v-if="item.type !== 'ERC-20'">
                {{ item.type }}
              </div>
            </a>
            <div
              class="risk"
              :class="{
                high: item.risk === 'high',
                medium: item.risk === 'medium',
                low: item.risk === 'low',
              }"
              v-if="item.risk"
            >
              <img
                :src="
                  item.risk === 'high'
                    ? require('../assets/risk_high.png')
                    : item.risk === 'medium'
                    ? require('../assets/risk_medium.png')
                    : require('../assets/risk_low.png')
                "
              />
              <span>{{
                item.risk === "high"
                  ? $t("highRisk")
                  : item.risk === "medium"
                  ? $t("mediumRisk")
                  : $t("lowRisk")
              }}</span>
            </div>
          </div>
          <div class="data-info">
            <div class="data-info-item">
              <span v-if="currentChain.chainId !== '728126428'" class="name">{{
                `${$t("contractAddress")}:`
              }}</span>
              <span v-else class="name">{{ $t("toAddress") }}</span>
              <a
                class="value"
                target="_blank"
                :href="item.approveContractUrl || '#'"
              >
                <span v-if="!item.transitSwap && !item.isMultiChain">
                  {{ item.contract_name || item.approved_contract | omit }}
                </span>
                <span v-else-if="item.isMultiChain"> Multichain </span>
                <span v-else> TransitSwap </span>
                <img
                  :src="
                    item.safety === 'safe'
                      ? require('../assets/correct@2x.png')
                      : item.safety === 'unsafe'
                      ? isDarkMode
                        ? require('../assets/risk_dark@2x.png')
                        : require('../assets/risk@2x.png')
                      : isDarkMode
                      ? require('../assets/jump_dark.png')
                      : require('../assets/jump.png')
                  "
                />
              </a>
            </div>
            <div
              class="data-info-item"
              v-if="
                item.type === 'ERC-20' || currentChain.chainId === '728126428'
              "
            >
              <span class="name">{{ `${$t("approveAmount")}:` }}</span>
              <span class="value" :title="item.approved_amount">{{
                item.approved_amount
              }}</span>
            </div>
            <div class="data-info-item" v-if="item.initial_approval_time">
              <span class="name">{{ `${$t("firstApproveTime")}:` }}</span>
              <span class="value">{{ item.initial_approval_time }}</span>
            </div>
            <div class="data-info-item" v-if="item.time">
              <span class="name">{{ `${$t("approveTime")}:` }}</span>
              <span class="value">{{ item.time }}</span>
            </div>
            <div class="data-info-item" v-if="item.initial_approval_hash">
              <span class="name">{{ `${$t("approveHash")}:` }}</span>
              <a
                class="value"
                target="_blank"
                :href="item.approveHashUrl || '#'"
              >
                <span>{{ item.initial_approval_hash | omit }}</span>
                <img
                  :src="
                    isDarkMode
                      ? require('../assets/jump_dark.png')
                      : require('../assets/jump.png')
                  "
                />
              </a>
            </div>
          </div>
          <div
            class="cancel"
            :class="{
              offset: item.type !== 'ERC-20',
              disabled:
                cancelDisabled ||
                item.unapproving ||
                (currentChain.chainId === '728126428' &&
                  item.approved_amount == 0),
            }"
            @click="cancelApprove(item)"
          >
            <div
              class="cancel-text"
              v-if="currentChain.chainId !== '728126428'"
            >
              <img src="../assets/loading-blue.png" v-if="item.unapproving" />
              <span>
                {{ item.unapproving ? $t("unapproving") : cancelBtnContent }}
              </span>
            </div>
            <div class="cancel-text" v-else>
              <img src="../assets/loading-blue.png" v-if="item.unapproving" />
              <span v-if="item.unapproving">
                {{ item.unapproving ? $t("unapproving") : cancelBtnContent }}
              </span>
              <span v-else-if="item.approved_amount == 0">
                {{ $t("cancelled") }}
              </span>
              <span v-else-if="cancelDisabled">
                {{ $t("switchAddress") }}
              </span>
              <span v-else>
                {{ $t("cancelApprove") }}
              </span>
            </div>
          </div>
        </div>
        <div class="footer-logo" href="https://gopluslabs.io/">
          <!-- <img
            class="go-plus"
            src="../assets/go-plus-dark.png"
            v-if="isDarkMode"
          />
          <img class="go-plus" src="../assets/go-plus.png" v-else /> -->
          <div class="dark-mode" v-if="isDarkMode">
            <div class="go-plus">
              <span>Powered by</span>
              <a class="logo" href="https://gopluslabs.io/"></a>
            </div>
            <span class="and">&</span>
            <div class="ok-link">
              <a class="logo" href="https://www.oklink.com"></a>
            </div>
          </div>
          <div class="light-mode" v-else>
            <div class="go-plus">
              <span>Powered by</span>
              <a class="logo" href="https://gopluslabs.io/"></a>
            </div>
            <span class="and">&</span>
            <div class="ok-link">
              <a class="logo" href="https://www.oklink.com"></a>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import tp from "tp-js-sdk";
import _ from "lodash";
import dayjs from "dayjs";
import { CHAIN_MAP, TOKEN_ABI, NFT_ABI } from "./constants";
import Switcher from "./Switcher.vue";
import axios from "axios";
import Web3 from "web3";
import { TP_SERVER_DOMAIN, TP_FORWARD_DOMAIN } from "../config.js";
import {
  ETH_MULTICHAIN,
  BSC_MULTICHAIN,
  POLYGON_MULTICHAIN,
  ARB_MULTICHAIN,
  OP_MULTICHAIN,
  SYMBOL_CONTRACT_MAP,
  timestamp,
  logoUrl,
  logoUrl2,
} from "../utils";
import FakeWallet from "./FakeWallet.vue";
import LauguagesVue from "./Lauguages.vue";
import MobileLanguages from "./MobileLanguages.vue";

// eth
// 0x4184d9a175d13e568f3466ea93c02b6f8eb9f8c1
// 0xb01a9a7ef5779fa8680898827cc2b1aed638ee2d
// 0x9865EeBdD1cE65f45b6247AEEd2fA2252ECA7A08
// 0x3E992b0dFB1aA0adD70543ed0D13262c136Fe6B3  （高风险）

// bsc
// 0x4184d9a175d13e568f3466ea93c02b6f8eb9f8c1
// 0xb01a9a7ef5779fa8680898827cc2b1aed638ee2d
// 0x9865EeBdD1cE65f45b6247AEEd2fA2252ECA7A08
// 0xeD1afC8C4604958C2F38a3408FA63B32E737c428  （高风险）

// tron
// TDvXH5BY291y9MdyLXyTMysYQ67sf6bqZe
// TTLaNDdcL5rMfxMS2VL1UCa44ebRCNbqew  （高风险）
// TUrJB7eRxJWya938prRbiFqmYQga7iXA2L

const tronWhiteAddressList = [
  "TDvXH5BY291y9MdyLXyTMysYQ67sf6bqZe",
  "TUrJB7eRxJWya938prRbiFqmYQga7iXA2L",
  "TKzxdSv2FZKQrEqkKVgp5DcwEXBEKMg2Ax",
  "TC8hD4aA9Zy4wMofGVE1tw6gjPGHrEfsNW",
  "TZFs5ch1R1C4mmjwrrmZqeqbUgGpxY1yWB",
  "TTfvyrAz86hbZk5iDpKD78pqLGgi8C7AAw",
  "TSiiYf1b1PV1fpT9T7V4wy11btsNVajw1g",
  "TKzxdSv2FZKQrEqkKVgp5DcwEXBEKMg2Ax",
  "TU2MJ5Veik1LRAgjeSzEdvmDYx7mefJZvd",
  "TFVisXFaijZfeyeSjCEVkHfex7HGdTxzF9",
];

const ETHBSCWhiteAddressList = [
  "0x4184d9a175d13e568f3466ea93c02b6f8eb9f8c1",
  "0xb01a9a7ef5779fa8680898827cc2b1aed638ee2d",
  "0x9865eebdd1ce65f45b6247aeed2fa2252eca7a08",
  "0x00000047bb99ea4d791bb749d970de71ee0b1a34",
];

const pancakeSwapWhiteAddressList = [
  "0x13f4ea83d0bd40e75c8222255bc855a974568dd4",
];

const ETHBSCHighAddressList = [
  "0x3e992b0dfb1aa0add70543ed0d13262c136fe6b3",
  "0xed1afc8c4604958c2f38a3408fa63b32e737c428",
];

const okcWhiteAddressList = [];
const maticWhiteAddressList = [];
const ftmWhiteAddressList = [];
const arbWhiteAddressList = [];
const avaxWhiteAddressList = [];

const safeContractAddress = [
  "tr7nhqjekqxgtci8q8zy4pl8otszgjlj6t",
  "tcfcgjekyqmdyt1yxfum5v5sdtay6tuwik",
];

export default {
  name: "HelloWorld",
  components: { Switcher, FakeWallet, LauguagesVue, MobileLanguages },
  data() {
    return {
      currentChain: {
        logo: "",
        name: "",
        chainId: "",
      },
      isDarkMode: false,
      modalShow: false,
      address: "",
      status: "init",
      chainListVisible: false,
      defaultLogo: "https://tokenpocket.gz.bcebos.com/common.png",
      chainMap: {},
      chainMapList: [],
      tokenApprovalData: [],
      nft721ApprovalData: [],
      nft1155ApprovalData: [],
      okTokenApprovalData: [],
      okNft721ApprovalData: [],
      okNft1155ApprovalData: [],
      tronList: [],
      okLinkApprovalList: [],
      // protocolType: 'token_20',
      type: "all",
      typeList: ["all", "ERC-20", "ERC-721", "ERC-1155"],
      web3: undefined,
      userAddress: "",
      selectedAddress: "",
      selectedChainId: "",
      confirmVisible: true,
      interval: null,
      requestId: 0,
      tronPageNum: 1,
      timeoutIDs: [],
      promiseList: [],
      isShowAll: false,
      curSelectIndex: undefined,
      isFakeWallet: false,
      tokenList: [],
      searchQuery: "",
    };
  },
  computed: {
    rightArrow() {
      return require(`../assets/arrow-w.png`);
    },
    queryObj() {
      const obj = {
        address: this.address.trim(),
        chain_id: this.currentChain.chainId,
      };
      return obj;
    },
    closePng() {
      return require(`../assets/close${
        this.isDarkMode ? "dark" : "bright"
      }.png`);
    },
    filteredChains() {
      // 当搜索查询为空时或者等于全部，显示全部
      if (
        this.searchQuery.trim().length === 0 ||
        this.searchQuery.trim() === this.currentChain.name
      ) {
        return this.chainMapList; // 当搜索查询为空时，显示全部
      }
      return this.chainMapList.filter((chain) =>
        this.chainMap[chain].name
          .toLowerCase()
          .includes(this.searchQuery.trim().toLowerCase())
      );
    },
    tronApproveList() {
      return this.tronList.filter((item) => item.approved_amount != "0");
    },
    tronCancelList() {
      return this.tronList.filter((item) => item.approved_amount == "0");
    },
    list() {
      let approveList = [];
      if (
        this.currentChain.chainId === "1" ||
        this.currentChain.chainId === "56" ||
        this.currentChain.chainId === "728126428"
      ) {
        if (this.type === "all") {
          approveList = this.tokenApprovalData
            .concat(this.nft721ApprovalData)
            .concat(this.nft1155ApprovalData);
        } else if (this.type === "ERC-20") {
          approveList = this.tokenApprovalData;
        } else if (this.type === "ERC-721") {
          approveList = this.nft721ApprovalData;
        } else if (this.type === "ERC-1155") {
          approveList = this.nft1155ApprovalData;
        } else if (this.type === "tron") {
          // return this.tronList;
          if (this.isShowAll) {
            // 把授权数量为0的放到前面
            let arr = this.tronList.filter((item) => item.approved_amount == 0);
            let arr1 = this.tronList.filter(
              (item) => item.approved_amount != 0
            );
            approveList = [...arr, ...arr1];
          } else {
            approveList = this.tronApproveList;
          }
        }
      } else {
        // return this.okLinkApprovalList
        if (this.type === "ERC-20") {
          approveList = this.okTokenApprovalData.filter((item) => {
            if (
              item.isMultiChain &&
              item.expired &&
              (item.logo === logoUrl ||
                item.logo === logoUrl2 ||
                item.logo === "")
            ) {
              return false;
            } else {
              return true;
            }
          });
        } else if (this.type === "ERC-721") {
          approveList = this.okNft721ApprovalData;
        } else {
          approveList = this.okNft1155ApprovalData;
        }
      }
      // console.log('approveList', approveList);
      return approveList.filter((item) => !item.isFraud);
    },
    cancelBtnContent() {
      if (this.selectedChainId !== this.currentChain.chainId) {
        return this.$t("switchChain", {
          network:
            CHAIN_MAP[this.currentChain.chainId] &&
            CHAIN_MAP[this.currentChain.chainId].name,
        });
      } else if (
        (this.selectedAddress || "").toLowerCase() !==
        (this.userAddress || "").toLowerCase()
      ) {
        return this.$t("switchAddress");
      } else {
        return this.$t("cancelApprove");
      }
    },
    cancelDisabled() {
      const disabled =
        this.selectedChainId !== this.currentChain.chainId ||
        (this.selectedAddress || "").toLowerCase() !==
          (this.userAddress || "").toLowerCase();
      return disabled;
    },
    tronListStart() {
      return (this.tronPageNum - 1) * 50;
    },
  },
  filters: {
    omit(val) {
      if (val.length < 15) return val;
      return (
        val.substring(0, 8) + "..." + val.substring(val.length - 8, val.length)
      );
    },
    omitAddress(val) {
      if (val.length < 10) return val;
      return (
        val.substring(0, 5) + "..." + val.substring(val.length - 3, val.length)
      );
    },
  },
  watch: {
    currentChain(val) {
      if (val.chainId != "1" && val.chainId != "56") {
        this.typeList = ["ERC-20", "ERC-721", "ERC-1155"];
        this.type = "ERC-20";
      } else {
        this.typeList = ["all", "ERC-20", "ERC-721", "ERC-1155"];
      }
      console.log("--->", val.name);
      this.searchQuery = val.name;
    },
    type(val) {
      let protocolType = "token_20";
      if (val === "ERC-721") {
        protocolType = "token_721";
      } else if (val === "ERC-1155") {
        protocolType = "token_1155";
      }
    },
    isDarkMode(val) {
      localStorage.setItem("darkmode", val ? "1" : "0");
      if (val) {
        document.documentElement.setAttribute("theme", "dark");
        document.body.style.background = "var(--bg-1)";
      } else {
        document.documentElement.removeAttribute("theme");
        document.body.style.background = "var(--bg-0)";
      }
    },
    queryObj(n) {
      this.$router.replace({
        path: `/`,
        query: { ...n },
      });
    },
    // list(val) {
    //   if (val && val.length) {
    //     this.status = 'data'
    //   } else {
    //     this.status = 'noData'
    //   }
    // }
  },
  created() {
    const { address, chain_id } = this.$route.query;
    if (address && chain_id) {
      this.status = "loading";
    }
    this.getTransitSwapContract();
    if (sessionStorage.getItem("data")) {
      try {
        const data = JSON.parse(sessionStorage.getItem("data"));
        Object.assign(this, data);
        if (ethereum) {
          this.web3 = new Web3(ethereum);
        } else {
          this.interval = setInterval(() => {
            if (ethereum) {
              this.web3 = new Web3(ethereum);
              clearInterval(this.interval);
              this.interval = null;
            }
          }, 300);
        }
        this.login();
        return;
      } catch (err) {
        console.log("err", err);
      }
    }
    if (localStorage.getItem("darkmode")) {
      this.isDarkMode = localStorage.getItem("darkmode") === "1";
    }
    tp.isDarkMode().then((res) => {
      this.isDarkMode = Boolean(res.data);
    });
    if (this.web3) {
      this.web3 = new Web3(window.ethereum);
    } else {
      this.interval = setInterval(() => {
        if (window.ethereum) {
          this.web3 = new Web3(window.ethereum);
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 300);
    }
    this.getChainMap()
      .then(this.login)
      .catch(() => {
        const map = {};
        map["728126428"] = CHAIN_MAP["728126428"];
        map["1"] = CHAIN_MAP["1"];
        map["56"] = CHAIN_MAP["56"];
        map["66"] = CHAIN_MAP["66"];
        map["137"] = CHAIN_MAP["137"];
        map["250"] = CHAIN_MAP["250"];
        map["42161"] = CHAIN_MAP["42161"];
        map["43114"] = CHAIN_MAP["43114"];
        this.currentChain = { ...CHAIN_MAP["1"], chainId: 1 };
        this.chainMap = map;
        let arr = Object.values(this.chainMap);
        this.sortChainMapList(arr);
      });
    window.addEventListener("message", this.onTronLink);
    window.onscroll = this.onscrollBottom;
  },
  methods: {
    /** type = 0,获取标签列表, type = 1,获取诈骗地址列表 */
    goHome() {
      this.status = "init";
    },
    clearInput() {
      this.searchQuery = "";
      this.chainListVisible = true;
    },
    resetQuery() {
      this.chainListVisible = !this.chainListVisible;
      this.searchQuery = this.currentChain.name;
    },
    stoppop(event) {
      event.stopPropagation();
    },
    changModalVisiable(bool) {
      this.modalShow = bool;
    },
    async getLabelList1(list, type) {
      if (!list.length) {
        return;
      }
      let contractList = [];
      list.forEach((item) => {
        if (item.approved_contract) {
          contractList.push(item.approved_contract);
        }
      });
      // 去重
      contractList = _.uniqBy(contractList);
      // console.log('contractList', contractList);
      let data = {
        items: contractList,
      };
      if (type === 1) {
        (data.item_type = 1), (data.label_type = "fraud");
      }
      try {
        const response = await fetch(`${TP_SERVER_DOMAIN}/v1/label/batch_tag`, {
          method: "POST",
          body: JSON.stringify(data),
        });
        return await response.json();
        // console.log('res', res);
      } catch (error) {
        console.log(error);
      }
    },
    async getFakeWalletData(address) {
      this.isFakeWallet = false;
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/wallet/leaked?address=${address}`
        );
        const res = await response.json();
        if (res.result === 0) {
          this.isFakeWallet = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /** 这里请求会跨域，使用postman获取支持的链的数据 */
    async getOkLinkBlockchain() {
      try {
        const response = await fetch(
          `https://www.oklink.com/api/v5/explorer/blockchain/summary`,
          {
            headers: {
              "Ok-Access-Key": "390bc452-b432-4a04-b093-1add41d638b8",
            },
          }
        );
        const res = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    async getOkLinkApproveList(protocolType) {
      try {
        const response = await fetch(
          `${TP_SERVER_DOMAIN}/v1/oklink/api/v5/tracker/contractscanner/token-authorized-list?address=${this.address}&chainShortName=${this.currentChain.chainShortName}&protocolType=${protocolType}`
        );
        return await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    onMouseEnter(index) {
      if (!this.cancelDisabled) {
        this.curSelectIndex = index;
      }
    },
    onShow() {
      this.isShowAll = !this.isShowAll;
    },
    // 获取 transit swap 合约地址
    getTransitSwapContract() {
      axios
        .get("https://aggserver.mytokenpocket.vip/v4/common/contracts")
        .then((res) => {
          if (res.data && res.data.result === 0 && res.data.data) {
            const tronContract = res.data.data.previousRouters?.TRON || [];
            const ethContract = res.data.data.previousRouters?.ETH || [];
            const bscContract = res.data.data.previousRouters?.BSC || [];
            const okcContract = res.data.data.previousRouters?.OEC || [];
            const maticContract = res.data.data.previousRouters?.MATIC || [];
            const ftmContract = res.data.data.previousRouters?.FTM || [];
            const arbContract = res.data.data.previousRouters?.ARB || [];
            const avaxContract = res.data.data.previousRouters?.AVAX || [];
            tronWhiteAddressList.push(...tronContract);
            ethContract.forEach((eth) => {
              ETHBSCWhiteAddressList.push(eth.toLowerCase());
            });
            bscContract.forEach((bsc) => {
              ETHBSCWhiteAddressList.push(bsc.toLowerCase());
            });
            okcContract.forEach((okc) => {
              okcWhiteAddressList.push(okc.toLowerCase());
            });
            maticContract.forEach((mat) => {
              maticWhiteAddressList.push(mat.toLowerCase());
            });
            ftmContract.forEach((ftm) => {
              ftmWhiteAddressList.push(ftm.toLowerCase());
            });
            arbContract.forEach((arb) => {
              arbWhiteAddressList.push(arb.toLowerCase());
            });
            avaxContract.forEach((avax) => {
              avaxWhiteAddressList.push(avax.toLowerCase());
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onscrollBottom() {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight) {
        //到了这个就可以进行业务逻辑加载后台数据了
        // console.log('到了底部')
        if (this.currentChain.chainId === "728126428") {
          this.getTronApproveList();
        }
      }
    },
    getChainMap() {
      return new Promise((resolve, reject) => {
        axios
          .get(TP_FORWARD_DOMAIN + "/v1/token/token_security_support_chain")
          .then((res) => {
            if (res.data && res.data.data) {
              const data = res.data.data;
              const map = {};
              for (let i = 0; i < data.length; i++) {
                if (CHAIN_MAP[data[i].id]) {
                  map[data[i].id] = CHAIN_MAP[data[i].id];
                  if (i === 0 || data[i].id === "56") {
                    this.currentChain = {
                      ...CHAIN_MAP[data[i].id],
                      chainId: data[i].id,
                    };
                    console.log("----++>", this.currentChain.name);
                  }
                } else {
                  map[data[i].id] = data[i];
                  delete map[data[i].id].id;
                  if (i === 0 || data[i].id === "56") {
                    this.currentChain = data[i];
                    console.log("----++>", this.currentChain.name);
                  }
                }
              }
              map["728126428"] = CHAIN_MAP["728126428"];
              map["66"] = CHAIN_MAP["66"];
              map["137"] = CHAIN_MAP["137"];
              map["250"] = CHAIN_MAP["250"];
              map["42161"] = CHAIN_MAP["42161"];
              map["43114"] = CHAIN_MAP["43114"];
              this.chainMap = map;
              let arr = Object.values(this.chainMap);
              this.sortChainMapList(arr);
              const { address, chain_id } = this.$route.query;
              if (address && chain_id) {
                this.address = address;
                this.switchChain(chain_id);
              }
              // this.searchQuery = this.currentChain.name;
              resolve();
            }
          })
          .catch(reject);
      });
    },
    sortChainMapList(arr) {
      arr.sort((a, b) => {
        if (a.order < b.order) {
          return -1; // a 在 b 前面
        } else if (a.name > b.name) {
          return 1; // a 在 b 后面
        } else {
          return 0; // a 和 b 相等
        }
      });
      arr = arr.map((item) => item.chainId);
      this.chainMapList = arr;
    },
    async confirm(type) {
      if (type !== "refresh") {
        if (!this.address) return;
        this.status = "loading";
        this.userAddress = this.address.trim();
      }
      this.address = this.address.trim();
      this.getFakeWalletData(this.address);

      this.requestId += 1;
      const currentRequestId = this.requestId;
      // 重置查询第一页
      this.tronPageNum = 1;
      this.tronList = [];
      this.curSelectIndex = undefined;
      if (this.currentChain.chainId === "728126428") {
        this.getTronApproveList();
      } else if (
        this.currentChain.chainId !== "1" &&
        this.currentChain.chainId !== "56"
      ) {
        let promiseToken20 = this.getOkLinkApproveList("token_20");
        let promiseToken721 = this.getOkLinkApproveList("token_721");
        let promiseToken1155 = this.getOkLinkApproveList("token_1155");
        Promise.all([promiseToken20, promiseToken721, promiseToken1155])
          .then((results) => {
            // console.log('all', results);
            this.getOkLinkData(results[0].data, "token_20");
            this.getOkLinkData(results[1].data, "token_721");
            this.getOkLinkData(results[2].data, "token_1155");
          })
          .catch(() => {
            this.status = "noData";
          });
      } else {
        axios
          .get(TP_FORWARD_DOMAIN + "/v1/token/token_security_approval", {
            params: {
              chain_id: this.currentChain.chainId,
              address: type === "refresh" ? this.userAddress : this.address,
              ns: "ethereum",
            },
          })
          .then((res) => {
            this.type = "all";
            if (this.requestId !== currentRequestId) return;
            if (res.data && res.data.data) {
              // this.tokenApprovalData = this.getData(
              //   res.data.data.token_approval_data || [],
              //   'ERC-20'
              // );
              this.getData(res.data.data.token_approval_data || [], "ERC-20");
              // this.nft721ApprovalData = this.getData(
              //   res.data.data.nft721_approval_data || [],
              //   'ERC-721'
              // );
              this.getData(res.data.data.nft721_approval_data || [], "ERC-721");
              // this.nft1155ApprovalData = this.getData(
              //   res.data.data.nft1155_approval_data || [],
              //   'ERC-1155'
              // );
              this.getData(
                res.data.data.nft1155_approval_data || [],
                "ERC-1155"
              );
              // if (
              //   this.tokenApprovalData.length ||
              //   this.nft721ApprovalData.length ||
              //   this.nft1155ApprovalData.length
              // ) {
              //   this.status = 'data';
              // } else {
              //   this.status = 'noData';
              // }
            } else {
              throw new Error("No data");
            }
          })
          .catch((err) => {
            // console.log(err)
            this.status = "noData";
          });
      }
    },
    async getTronApproveList() {
      this.promiseList = [];
      try {
        const response = await fetch(
          `https://apilist.tronscanapi.com/api/account/approve/list?address=${this.address}&limit=50&start=${this.tronListStart}&type=project`
        );
        const res = await response.json();
        // console.log('res', res)
        this.type = "tron";

        let list = [];
        if (Array.isArray(res.data)) {
          res.data.forEach(async (item) => {
            let tronObj = {};
            tronObj.approved_amount = item.unlimited
              ? "Unlimited"
              : item.amount;
            // tronObj.unapproving = item.unlimited
            tronObj.unapproving = false;
            tronObj.token_symbol = item.tokenInfo.tokenName;
            tronObj.approved_contract = item.to_address;
            tronObj.contract_address = item.contract_address;
            // tronObj.logo = item.tokenInfo.tokenLogo;
            tronObj.hash = "";
            tronObj.type = "ERC-20";
            tronObj.transitSwap = false;
            tronObj.isFraud = false;
            // tronObj.time = item.operate_time
            // if (item.to_address === 'TKqQ7zuL7yKp1NWuhGpoXTDiE3j2qiAbRE') {
            //   tronObj.risk = 'low'
            if (
              // 低风险判断，先判断是否在我们的白名单里面，然后判断项目中是否有 officalWebsite 字段的值为 justlend.org 或者 sunswap.com
              tronWhiteAddressList.indexOf(item.to_address) > -1 ||
              (item.project &&
                item.project.officalWebsite &&
                (item.project.officalWebsite === "justlend.org" ||
                  item.project.officalWebsite === "sunswap.com"))
            ) {
              tronObj.risk = "low";
            }
            if (tronWhiteAddressList.indexOf(item.to_address) > -1) {
              tronObj.transitSwap = true;
            }
            // 授权数量小于 20000 标记为中风险
            else if (Number(item.approved_amount) < 20000) {
              tronObj.risk = "medium";
            } else {
              tronObj.risk = "high";
            }
            // 代币名称包含USDT、UDST、Tether、Tather、Tethar关键词的，合约地址不是TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t的都将 symbol 改成：“钓鱼代币，请忽略”
            // const pattern = /(𝖴𝖲𝖣𝖳|usdt|udst|tether|tather|tethar)/i;
            const pattern = /(𝖴𝖲𝖣𝖳|usdt|udst|tether|tather|tethar|U5DT)/i;
            tronObj.tokenAddressUrl = `https://tronscan.io/#/token20/${item.contract_address}`;
            tronObj.approveContractUrl = `https://tronscan.io/#/contract/${item.to_address}/code`;
            tronObj.logo = item.tokenInfo.tokenLogo;
            this.getTronTokenLogo(10, item.contract_address, tronObj);
            if (
              pattern.test(item.tokenInfo.tokenName) &&
              safeContractAddress.indexOf(
                item.contract_address.toLowerCase()
              ) === -1
            ) {
              tronObj.token_symbol = this.$t("phishingToken");
            } else {
              list.push(tronObj);
            }
          });
        }
        if (navigator.userAgent.indexOf("TokenPocket") === -1) {
          this.switchTronLink();
        }
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.status = "data";
          this.tronPageNum += 1;
          this.tronList = [...this.tronList, ...list];
          this.getAllLabelList(this.tronList, null, "tron");
          // this.tronList = this.tronList.filter(
          //   (item) => item.approved_amount != "0"
          // );
        } else if (
          res.data &&
          Array.isArray(res.data) &&
          res.data.length === 0 &&
          this.tronPageNum === 1
        ) {
          this.status = "noData";
        } else if (!res.data) {
          this.status = "noData";
        }
      } catch (error) {
        this.status = "noData";
      }
    },
    getOkLinkData(data, type) {
      let list = [];
      let whiteList = [];
      let multiChainList = [];
      if (type === "token_20") {
        type = "ERC-20";
      } else if (type === "token_721") {
        type = "ERC-721";
      } else {
        type = "ERC-1155";
      }
      for (let token of data) {
        switch (token.chainShortName) {
          case "OKTC":
            whiteList = okcWhiteAddressList;
            break;
          case "POLYGON":
            whiteList = maticWhiteAddressList;
            multiChainList = POLYGON_MULTICHAIN;
            break;
          case "FTM":
            whiteList = ftmWhiteAddressList;
            break;
          case "ARBITRUM":
            whiteList = arbWhiteAddressList;
            multiChainList = ARB_MULTICHAIN;
            break;
          case "ARBITRUM":
            whiteList = arbWhiteAddressList;
            break;
          case "AVAXC":
            whiteList = avaxWhiteAddressList;
            break;
        }
        const approvedList = token.authorizedList.map((item) => {
          let safety, risk;
          let transitSwap = false;
          if (whiteList.indexOf(item.approvedContractAddress) > -1) {
            risk = "low";
            safety = "safe";
            transitSwap = true;
          } else {
            risk = "high";
            safety = "unsafe";
          }
          let approvedAmount = item.approvedAmount;
          if (item.approvedAmount === "unlimited") {
            approvedAmount = "Unlimited";
          }
          if (multiChainList.indexOf(item.approvedContractAddress) > -1) {
            // console.log('isMultiChain', item);
            item.isMultiChain = true;
          } else {
            item.isMultiChain = false;
          }

          if (item.approvedTime && item.approvedTime > timestamp) {
            item.expired = true;
          } else {
            item.expired = false;
          }

          let obj = {
            logo: item.logo,
            // contract_name: token.tokenFullName,
            token_address: token.tokenContractAddress,
            token_symbol: token.tokenFullName,
            approved_contract: item.approvedContractAddress,
            contract_name: item.approvedProjectName
              ? item.approvedProjectName
              : item.approvedContractAddress,
            approved_amount: approvedAmount,
            isMultiChain: item.isMultiChain,
            expired: item.expired,
            time: item.approvedTime
              ? dayjs(Number(item.approvedTime)).format("YYYY-MM-DD HH:mm:ss")
              : "-",
            initial_approval_hash: item.approvedTxId,
            type,
            safety,
            risk,
            transitSwap,
            unapproving: false,
            isFraud: false,
            tokenAddressUrl:
              this.chainMap[this.currentChain.chainId] &&
              this.chainMap[this.currentChain.chainId].tokenExplorer.replace(
                "{account}",
                token.tokenContractAddress
              ),
            approveContractUrl:
              this.chainMap[this.currentChain.chainId] &&
              this.chainMap[this.currentChain.chainId].explorer.replace(
                "{account}",
                item.approvedContractAddress
              ),
            approveHashUrl:
              this.chainMap[this.currentChain.chainId] &&
              this.chainMap[this.currentChain.chainId].hashExplorer.replace(
                "{hash}",
                item.approvedTxId
              ),
          };
          this.getTronTokenLogo(
            this.currentChain.blockchain_id,
            token.tokenContractAddress,
            obj
          );
          return obj;
        });
        list = list.concat(approvedList);
        // list = list.concat(mList);
      }
      // console.log('list', list);
      list = list.filter((item) => {
        if (
          item.approved_amount === "Unlimited" ||
          Number(item.approved_amount) > 0
        ) {
          return true;
        }
      });
      this.getAllLabelList(list, type, "ok-link");
    },
    getData(data, type) {
      let list = [];
      let multiChainList = [];
      if (this.currentChain.chainId == 1) {
        multiChainList = ETH_MULTICHAIN;
      } else {
        multiChainList = BSC_MULTICHAIN;
      }
      for (let token of data) {
        const isHide = this.needToHide(token.token_address, token.token_symbol);
        // console.log('isHide', isHide);
        const approvedList = token.approved_list.map((item) => {
          let safety, risk;
          let transitSwap = false;
          if (item.address_info) {
            const address_info = item.address_info;
            if (address_info.trust_list === 1) {
              safety = "safe";
              if (
                item.approved_amount === "Unlimited" ||
                item.approved_amount >= 20000
              ) {
                if (address_info.is_contract && address_info.is_open_source) {
                  risk = "low";
                } else {
                  risk = "medium";
                }
              } else {
                risk = "medium";
              }
            } else {
              if (
                item.approved_amount === "Unlimited" ||
                item.approved_amount >= 20000
              ) {
                risk = "high";
              } else {
                if (address_info.doubt_list === 1) {
                  risk = "high";
                } else {
                  if (address_info.is_contract) {
                    if (address_info.is_open_source) {
                      risk = "medium";
                    } else {
                      risk = "high";
                    }
                  } else {
                    risk = "high";
                  }
                }
              }
            }
            if (address_info.doubt_list === 1) {
              safety = "unsafe";
            }
          } else {
            risk = "high";
          }
          // 根据 transit swap 合约判断风险
          if (
            ETHBSCWhiteAddressList.indexOf(
              item.approved_contract.toLowerCase()
            ) > -1
          ) {
            safety = "safe";
            risk = "low";
            transitSwap = true;
          } else if (
            pancakeSwapWhiteAddressList.indexOf(
              item.approved_contract.toLowerCase()
            ) > -1
          ) {
            safety = "safe";
            risk = "low";
          } else if (
            ETHBSCHighAddressList.indexOf(
              item.approved_contract.toLowerCase()
            ) > -1
          ) {
            safety = "unsafe";
            risk = "high";
          }

          if (multiChainList.indexOf(item.approved_contract) > -1) {
            // console.log('isMultiChain', item);
            item.isMultiChain = true;
          } else {
            item.isMultiChain = false;
          }
          if (
            item.initial_approval_time &&
            item.initial_approval_time * 1000 > timestamp
          ) {
            item.expired = true;
          } else {
            item.expired = false;
          }

          let tokenAddressUrl = "";
          let approveContractUrl = "";
          let approveHashUrl = "";
          if (this.chainMap[token.chain_id]) {
            if (this.$i18n.locale === "zh") {
              tokenAddressUrl = this.chainMap[
                token.chain_id
              ].tokenExplorer.replace(
                "{account}",
                token.token_address || token.nft_address
              );
              approveContractUrl = this.chainMap[
                token.chain_id
              ].explorer.replace("{account}", item.approved_contract);
              approveHashUrl = this.chainMap[
                token.chain_id
              ].hashExplorer.replace("{hash}", item.initial_approval_hash);
            } else {
              tokenAddressUrl = this.chainMap[
                token.chain_id
              ].tokenExplorerEn.replace(
                "{account}",
                token.token_address || token.nft_address
              );
              approveContractUrl = this.chainMap[
                token.chain_id
              ].explorerEn.replace("{account}", item.approved_contract);
              approveHashUrl = this.chainMap[
                token.chain_id
              ].hashExplorerEn.replace("{hash}", item.initial_approval_hash);
            }
          }

          return {
            logo: token.icon_url,
            contract_name: item.address_info && item.address_info.contract_name,
            token_symbol: token.token_symbol || token.nft_symbol,
            token_address: token.token_address || token.nft_address,
            approved_contract: item.approved_contract,
            approved_amount: item.approved_amount,
            isMultiChain: item.isMultiChain,
            expired: item.expired,
            isHide,
            malicious_address: token.malicious_address,
            malicious_behavior: token.malicious_behavior,
            // time: item.approved_time
            //   ? dayjs(item.approved_time * 1000).format('YYYY-MM-DD HH:mm:ss')
            //   : '-',
            initial_approval_time: item.initial_approval_time
              ? dayjs(item.initial_approval_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : "-",
            hash: item.hash,
            initial_approval_hash: item.initial_approval_hash,
            type,
            safety,
            risk,
            transitSwap,
            unapproving: false,
            isFraud: false,
            tokenAddressUrl,
            approveContractUrl,
            approveHashUrl,
          };
        });
        let filterApprovedList = approvedList.filter((fItem) => !fItem.isHide);
        let mList = filterApprovedList.filter((mItem) => {
          if (
            mItem.isMultiChain &&
            mItem.expired &&
            (mItem.logo === logoUrl ||
              mItem.logo === logoUrl2 ||
              mItem.logo === "")
          ) {
            return false;
          } else {
            return true;
          }
        });
        let list3 = mList.filter((l3) => {
          if (
            (l3.malicious_address === 1 ||
              l3.malicious_behavior.indexOf("gas_abuse") > -1) &&
            (l3.logo === logoUrl || l3.logo === logoUrl2 || l3.logo === "")
          ) {
            return false;
          } else {
            return true;
          }
        });
        list = list.concat(list3);
      }
      this.getAllLabelList(list, type, "go-plus");
      // return list;
    },
    // 0xf010a0400efa5319f82c1b2ba8008217c74b7165 ok
    // 0xf75a29b6e72e92950ecdf5357539375813eb6095 bsc
    /** list为第一个接口拿到的数据，type为代币类型， dataType为是go+数据还是okLink数据 */
    getAllLabelList(list, type, dataType) {
      if (list.length > 0) {
        const promise1 = this.getLabelList1(list, 0);
        const promise2 = this.getLabelList1(list, 1);
        Promise.all([promise1, promise2])
          .then((results) => {
            // console.log('results', results);
            results.forEach((result, index) => {
              let obj = result.data;
              list.forEach((item) => {
                for (const key in obj) {
                  if (index === 0) {
                    if (item.approved_contract === key && obj[key]) {
                      item.contract_name = obj[key].label;
                    }
                  } else if (index === 1) {
                    if (
                      item.approved_contract.toLowerCase() ===
                        key.toLowerCase() &&
                      obj[key]
                    ) {
                      item.isFraud = true;
                    }
                  }
                }
              });
            });
            // console.log('list', list);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.formatDataType(list, type, dataType);
          });
      } else {
        this.formatDataType(list, type, dataType);
      }
    },

    formatDataType(list, type, dataType) {
      if (dataType === "go-plus") {
        if (type === "ERC-20") {
          this.tokenApprovalData = list;
        } else if (type === "ERC-721") {
          this.nft721ApprovalData = list;
        } else {
          this.nft1155ApprovalData = list;
        }
        if (
          this.tokenApprovalData.length ||
          this.nft721ApprovalData.length ||
          this.nft1155ApprovalData.length
        ) {
          this.status = "data";
        } else {
          this.status = "noData";
        }
      } else if (dataType === "ok-link") {
        if (type === "ERC-20") {
          this.okTokenApprovalData = list;
        } else if (type === "ERC-721") {
          this.okNft721ApprovalData = list;
        } else {
          this.okNft1155ApprovalData = list;
        }
        if (
          this.okTokenApprovalData.length ||
          this.okNft721ApprovalData.length ||
          this.okNft1155ApprovalData.length
        ) {
          this.status = "data";
        } else {
          this.status = "noData";
        }
      } else if (dataType === "tron") {
        console.log("tron-data");
      }
      this.getTokenList();
    },

    typeChange(type) {
      this.type = type
      this.getTokenList()
    },

    getTokenList() {
      let approveList = [];
      if (
        this.currentChain.chainId === "1" ||
        this.currentChain.chainId === "56" ||
        this.currentChain.chainId === "728126428"
      ) {
        if (this.type === "all") {
          approveList = this.tokenApprovalData
            .concat(this.nft721ApprovalData)
            .concat(this.nft1155ApprovalData);
        } else if (this.type === "ERC-20") {
          approveList = this.tokenApprovalData;
        } else if (this.type === "ERC-721") {
          approveList = this.nft721ApprovalData;
        } else if (this.type === "ERC-1155") {
          approveList = this.nft1155ApprovalData;
        } else if (this.type === "tron") {
          // return this.tronList;
          if (this.isShowAll) {
            // 把授权数量为0的放到前面
            let arr = this.tronList.filter((item) => item.approved_amount == 0);
            let arr1 = this.tronList.filter(
              (item) => item.approved_amount != 0
            );
            approveList = [...arr, ...arr1];
          } else {
            approveList = this.tronApproveList;
          }
        }
      } else {
        // return this.okLinkApprovalList
        if (this.type === "ERC-20") {
          approveList = this.okTokenApprovalData.filter((item) => {
            if (
              item.isMultiChain &&
              item.expired &&
              (item.logo === logoUrl ||
                item.logo === logoUrl2 ||
                item.logo === "")
            ) {
              return false;
            } else {
              return true;
            }
          });
        } else if (this.type === "ERC-721") {
          approveList = this.okNft721ApprovalData;
        } else {
          approveList = this.okNft1155ApprovalData;
        }
      }
      // console.log('approveList', approveList);
      this.tokenList = approveList.filter((item) => !item.isFraud);
    },

    /** CHANGELOG.md 新增需求1 */
    needToHide(address, symbol) {
      let chain = SYMBOL_CONTRACT_MAP[this.currentChain.chainId];
      // console.log('chain', chain);
      if (chain) {
        // console.log('address', address);
        // console.log('symbol', symbol);
        if (chain[symbol]) {
          if (chain[symbol].indexOf(address.toLowerCase()) > -1) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    switchChain(chainId) {
      this.currentChain = { ...this.chainMap[chainId], chainId };
      this.chainListVisible = false;
      if (navigator.userAgent.indexOf("TokenPocket") == -1) {
        this.login("click");
      }
      if (this.status === "init") return;
      if (this.address) {
        this.confirm();
      } else {
        this.tokenApprovalData = [];
        this.nft721ApprovalData = [];
        this.nft1155ApprovalData = [];
        this.status = "noData";
      }
    },

    async getTronTokenLogo(blockchain_id, address, obj) {
      try {
        const response = await fetch(
          `https://preserver.mytokenpocket.vip/v1/swap_market/token_info?blockchain_id=${blockchain_id}&address=${address}`
        );
        const res = await response.json();
        if (res.result === 0) {
          obj.logo = res.data.icon;
        }
      } catch (error) {
        console.log(error);
      }
    },

    cancelApprove(info) {
      if (
        this.cancelDisabled ||
        info.unapproving ||
        (this.currentChain.chainId === "728126428" && info.approved_amount == 0)
      )
        return;
      if (this.currentChain.chainId === "728126428") {
        this.cancelTronApprove(info);
        return;
      }
      const preUserAddress = this.userAddress;
      const preChainId = this.currentChain.chainId;
      info.unapproving = true;
      if (info.type === "ERC-20") {
        const contract = new this.web3.eth.Contract(
          TOKEN_ABI,
          info.token_address
        );
        let data = contract.methods
          .approve(
            info.approved_contract,
            "0x0000000000000000000000000000000000000000000000000000000000000000"
          )
          .encodeABI();
        let param = {
          from: this.userAddress,
          to: info.token_address,
          data: data,
        };
        ethereum
          .request({
            method: "eth_sendTransaction",
            params: [param],
          })
          .then((hash) => {
            let setIntervalId = setInterval(() => {
              this.checkEVMTransitionByHash(hash, info, setIntervalId);
            }, 1000);
            this.timeoutIDs.push(setIntervalId);
          })
          .catch((err) => {
            console.log(err);
            info.unapproving = false;
          });
        // contract.methods
        //   .approve(
        //     info.approved_contract,
        //     '0x0000000000000000000000000000000000000000000000000000000000000000'
        //   )
        //   .send({ from: this.userAddress })
        //   .on('receipt', (receipt) => {
        //     if (
        //       receipt &&
        //       preUserAddress === this.userAddress &&
        //       preChainId === this.currentChain.chainId
        //     ) {
        //       setTimeout(() => {
        //         this.confirm('refresh');
        //       }, 500);
        //       info.unapproving = false;
        //     }
        //   })
        //   .on('error', (err) => {
        //     info.unapproving = false;
        //   });
      } else {
        const contract = new this.web3.eth.Contract(
          NFT_ABI,
          info.token_address
        );
        let data = contract.methods
          .setApprovalForAll(info.approved_contract, false)
          .encodeABI();
        let param = {
          from: this.userAddress,
          to: info.token_address,
          data: data,
        };
        ethereum
          .request({
            method: "eth_sendTransaction",
            params: [param],
          })
          .then((hash) => {
            let setIntervalId = setInterval(() => {
              this.checkEVMTransitionByHash(hash, info, setIntervalId);
            }, 1000);
            this.timeoutIDs.push(setIntervalId);
          })
          .catch((err) => {
            console.log(err);
            info.unapproving = false;
          });
        // const contract = new this.web3.eth.Contract(
        //   NFT_ABI,
        //   info.token_address
        // );
        // contract.methods
        //   .setApprovalForAll(info.approved_contract, false)
        //   .send({ from: this.userAddress })
        //   .on('receipt', (receipt) => {
        //     if (
        //       receipt &&
        //       preUserAddress === this.userAddress &&
        //       preChainId === this.currentChain.chainId
        //     ) {
        //       setTimeout(() => {
        //         this.confirm('refresh');
        //       }, 5000);
        //     }
        //   })
        //   .on('error', (err) => {
        //     info.unapproving = false;
        //   });
      }
    },
    checkEVMTransitionByHash(hash, info, setIntervalId) {
      this.web3.eth.getTransactionReceipt(hash).then((res) => {
        // console.log('res', res);
        if (res && res.status) {
          for (let i = 0; i < this.timeoutIDs.length; i++) {
            if (setIntervalId === this.timeoutIDs[i]) {
              clearInterval(setIntervalId);
            }
          }
          setTimeout(() => {
            this.confirm("refresh");
            info.unapproving = false;
          }, 5000);
        }
      });
    },
    async cancelTronApprove(info) {
      // TBbnpWu57v12nmXgZUz2kdp5hZu6CcwRdr
      info.unapproving = true;
      let contract = tronWeb.contract(TOKEN_ABI, info.contract_address);
      contract.methods
        .approve(info.approved_contract, "0")
        .send({ from: this.selectedAddress }, (err, hash) => {
          if (err) {
            info.unapproving = false;
            tronWeb.trx.getContract(info.contract_address).then((res) => {
              if (!res.bytecode) {
                alert("合约已销毁");
              }
            });
          } else {
            let setIntervalId = setInterval(() => {
              this.checkTransitionByHash(hash, info, setIntervalId);
            }, 1000);
            this.timeoutIDs.push(setIntervalId);
          }
        });
    },
    checkTransitionByHash(hash, info, setIntervalId) {
      tronWeb.trx.getTransactionInfo(hash).then((res) => {
        if (
          res.receipt &&
          res.receipt.result &&
          res.receipt.result === "SUCCESS"
        ) {
          info.unapproving = false;
          info.approved_amount = 0;
          for (let i = 0; i < this.timeoutIDs.length; i++) {
            if (setIntervalId === this.timeoutIDs[i]) {
              clearInterval(setIntervalId);
            }
          }
        }
      });
    },
    async login(type) {
      try {
        if (navigator.userAgent.indexOf("TokenPocket") > -1) {
          console.log("=======__");
          const walletObj = await tp.getCurrentWallet();
          // console.log('walletObj', walletObj);
          console.log("=======__", walletObj.data);
          if (walletObj.data?.ns === "tron") {
            // console.log('tron');
            this.address = this.selectedAddress = walletObj.data.address;
            this.selectedChainId = "728126428";
            if (this.chainMap["728126428"]) {
              this.currentChain = {
                ...this.chainMap["728126428"],
                chainId: "728126428",
              };
            }
            // console.log('chain', this.chainMap);
          } else {
            // const { address } = walletObj.data;
            let chainId = parseInt(ethereum.chainId, 16).toString();
            this.selectedAddress = ethereum.selectedAddress;
            this.address = ethereum.selectedAddress;
            // console.log(">>>>>", address, );
            if (this.address && chainId) {
              // this.selectedAddress = address
              // this.address = address
              // chainId = chain_id
              // console.log(">>>>>", address, chain_id);
              if (this.chainMap[chainId]) {
                this.currentChain = {
                  ...this.chainMap[chainId],
                  chainId: chainId,
                };
              }
            }
            this.selectedChainId = chainId;
          }
          if (type === "click") {
            this.switchTPWallet();
            return;
          }
        } else {
          if (this.currentChain.name === "Tron") {
            this.switchTronLink();
          } else {
            if (type !== "click") return;
            let account = await ethereum.request({
              method: "eth_requestAccounts",
            });
            this.selectedAddress = account[0];
            const chainId = parseInt(ethereum.chainId, 16).toString();
            this.selectedChainId = chainId;
            ethereum.on("accountsChanged", this.checkAddress);
            ethereum.on("chainChanged", this.checkChain);
            this.switchToETH().catch(() => {
              this.addETHChain()
                .then(() => {
                  this.switchToETH();
                })
                .catch((err) => {});
              this.switchToETH();
            });
            // if (this.chainMap[chainId]) {
            //   this.currentChain = { ...this.chainMap[chainId], chainId };
            // }
          }
        }
        // this.searchQuery = this.currentChain.name;
      } catch (error) {
        console.error(error);
      }
    },
    onTronLink(e) {
      if (e.data.message && e.data.message.action == "connect") {
        console.log("connect event", e.data.message.isTronLink);
      }
    },
    async switchTronLink() {
      if (window.tronLink && window.tronLink.ready) {
        console.log("已登录tronLink钱包");
        this.selectedAddress = tronLink.tronWeb.defaultAddress.base58;
        this.selectedChainId = "728126428";
      } else {
        console.log("请先登录tronLink钱包");
        const response = await tronLink.request({
          method: "tron_requestAccounts",
        });
        if (response.code === 200) {
          this.selectedAddress = tronLink.tronWeb.defaultAddress.base58;
          this.selectedChainId = "728126428";
        }
      }
    },
    switchTPWallet() {
      const blockchain_id =
        CHAIN_MAP[this.currentChain.chainId] &&
        CHAIN_MAP[this.currentChain.chainId].blockchain_id;
      const data = this.$data;
      delete data.web3;
      sessionStorage.setItem("data", JSON.stringify(data));
      tp.getWallet({
        walletTypes: [blockchain_id],
        switch: true,
      })
        .then((res) => {
          if (res.result) {
            // location.reload();
          }
        })
        .catch(console.log);
    },
    switchToETH() {
      return new Promise((resolve, reject) => {
        ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: CHAIN_MAP[this.currentChain.chainId].info.chainId,
              },
            ],
          })
          .then(resolve)
          .catch(reject);
      });
    },
    addETHChain() {
      return new Promise((resolve, reject) => {
        const info = CHAIN_MAP[this.currentChain.chainId].info;
        ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [info],
          })
          .then(resolve)
          .catch(reject);
      });
    },
    checkAddress(val) {
      this.selectedAddress = val && val[0];
    },
    checkChain(val) {
      this.selectedChainId = parseInt(val, 16).toString();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
@import "./helloWorld.less";
</style>
