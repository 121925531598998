const zh = {
  confirm: '确认',
  loading: '加载中...',
  noData: '查无记录',
  placeholder: '请输入您的地址',
  testResult: '检测结果',
  testWarn:
    '温馨提示：一般不限量的授权都伴随着巨大风险，建议您可以取消授权，也可以在进行授权的时候修改授权数量。',
  highRisk: '高风险',
  mediumRisk: '中风险',
  lowRisk: '低风险',
  contractAddress: '合约地址',
  toAddress: '授权地址:',
  approveAmount: '授权数量',
  approveTime: '授权时间',
  firstApproveTime: '首次授权时间',
  approveHash: '授权哈希',
  cancelApprove: '取消授权',
  all: '全部',
  switchAddress: '切换钱包后，可取消授权',
  switchChain: '切换到{network}网络后，可取消授权',
  connectWallet: '连接钱包',
  unapproving: '正在取消中…',
  cancelled: '已取消',
  contractDestroyed: '合约已销毁',
  phishingToken: '钓鱼代币，请忽略',
  riskWarning: '风险提示',
  riskWarningDesc:
    '我们收到了安全机构的数据支持，发现您的钱包地址曾在一个假钱包中出现。这意味着您的钱包目前面临着极高的被盗风险！为了确保您的资产安全，请立即按照以下安全教程进行钱包资产的迁移。',
  riskWarningDesc_2:
    '请注意，安全机构的数据不是实时更新的，为了避免使用到假钱包，请务必通过官方渠道下载钱包。我们不推荐通过搜索引擎搜索并下载钱包，更不能使用他人分享的安装包进行安装和使用！',
  officialWebsite: '官网',
  tutorials: '安全教程：《遇到假钱包后怎么紧急处理》',
  chain_not_found:'查无此链',
  settings:'设置',
  search_placeholder:'请输入网络名称',
  languages:'语言',
  darkmode:'暗黑模式',
};

export default zh;
